import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/reklama-telewizyjna/" className="sel">
              {" "}
              &gt; Reklama telewizyjna
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="wysiwyg">
                <video controls width="100%">
                  <source
                    src="/kcfinder/uploads/files/film/SUDAFED_HA_Adult_30s_YT.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem">
                    <div className="c1">
                      <span
                        className="photo"
                        style={{
                          backgroundImage:
                            'url("/web/kcfinder/uploads/files/artykuly/domowe_sposoby_na_walke_przezie%CC%A8bieniem.png")',
                        }}
                      />
                    </div>
                    <div className="c2">
                      Domowe sposoby na walkę z przeziębieniem{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/katar/poznaj_rodzaje_kataru">
                    <div className="c1">
                      <span
                        className="photo"
                        style={{
                          backgroundImage:
                            'url("/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png")',
                        }}
                      />
                    </div>
                    <div className="c2">Poznaj rodzaje kataru </div>
                  </a>
                </div>
              </div>
              <a href="/poradnik/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/napisporadnik.png" />
              </a>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
